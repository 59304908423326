<template>
    <v-card >

     <v-subheader>Add Calendar</v-subheader>
     <v-card-text>
         <v-text-field 
            v-model="title" 
            label="Title(*)"
            required
            outlined/>

             <v-date-picker
                v-model="dates"
                full-width
                range
             ></v-date-picker>

            <v-subheader>Start Time (UTC 기준)</v-subheader>

               <v-time-picker
                v-model="startTimePicker"
                format="24hr"
                full-width
                elevation="2"
                ></v-time-picker>


              <v-subheader>Color</v-subheader>
             <v-color-picker
                hide-inputs
                width="450"
                elevation="2"
                v-model="colorPicker"
                mode="hexa"
                dot-size="10"
                show-swatches
                swatches-max-height="150"
             ></v-color-picker>

        <v-subheader  class="mt-5">WillComming(날짜는 아무거나 선택해야함)</v-subheader>

          <v-text-field 
            v-model="willComming" 
            label="대략 날짜(5월초~6월말)"
            required
            outlined/>

          <v-textarea 
            v-model="summary" 
            label="Summary(*)"
            required
            outlined/>

         <v-textarea 
            v-model="body" 
            label="Body(*)"
            required
            outlined/>

         <v-text-field 
            v-model="link" 
            label="Link Url(*)"
            required
            outlined/>

        <v-text-field 
            v-model="imgLink" 
            label="Image Url"
            required
            outlined/>
 

     </v-card-text>
     <v-card-actions >
         <v-btn  outlined color="primary" @click="create" v-show="isUpdate==false"> Create
             <v-icon right>mdi-calendar-plus</v-icon>
         </v-btn>

         <v-btn  outlined color="secondary" @click="update" v-show="isUpdate==true"> Update
             <v-icon right>mdi-calendar-import</v-icon>
         </v-btn>

          <v-btn  outlined color="error" @click="cancel" v-show="isUpdate==true"> Cancel
             <v-icon right>mdi-calendar-remove</v-icon>
         </v-btn>

     </v-card-actions>

    </v-card>
</template>

<script>

String.prototype.replaceAll = function(a, b) {
  let s = this
  for(;;) {
    let s2 = s.replace(a, b)
    if (s2 == s) {
      return s
    }
    s = s2
  }
}

import { collection, addDoc ,doc, updateDoc  } from "firebase/firestore"; 
import { db } from '@/plugins/firebase';
// import { component } from 'vue/types/umd'
 

export default {
    data() {

        return {
            title:'',
            body:'',
            summary:'',
            link:'',
            imgLink:'',
            dates :[],
            colorPicker: null,
            startTimePicker:null,
            isUpdate:false,
            willUpdateDocID:'',
            willComming:''
        }
    },
   

    methods: {

        setUpdate(docData,docID){

            this.isUpdate = true;
            this.willUpdateDocID = docID;

            this.title = docData.title;
            this.body = docData.body.replaceAll('<br>','\n');
            this.summary = docData.summary.replaceAll('<br>','\n');
            this.link = docData.link;
            this.imgLink = docData.imgLink;
            this.dates = docData.endDate == null ? [docData.startDate] : [docData.startDate,docData.endDate];
            //this.colorPicker.hexa= docData.color;
            this.startTimePicker = docData.startTime;
            this.willComming = docData.willComming;

        },
        cancel(){

            this.isUpdate = false;

            this.title = '';
            this.body = '';
            this.summary = '';
            this.link = '';
            this.imgLink = '';
            this.dates = [];
            this.colorPicker= null;
            this.startTimePicker = null;
            this.willComming = '';

        },
        async update()
        {
 
           if(this.title =='' ||  this.body =='' ||  this.summary ==''||  this.link =='') return;
           if(this.dates.length == 0) return;

           const docRef = doc(db,'calendars', this.willUpdateDocID)
           await updateDoc(docRef, {

            title:  this.title,
            body: this.body.replaceAll('\n','<br>'),
            summary:this.summary.replaceAll('\n','<br>'),
            link: this.link,
            imgLink:this.imgLink,
            startDate:this.dates[0],
            endDate:this.dates.length == 2 ? this.dates[1]:null,
            color:this.colorPicker.hexa,
            startTime: this.startTimePicker,
            willComming:this.willComming
          });

          this.cancel();

        },

        async create(){

            // if(this.startTimePicker==null) return;
            // console.log( this.startTimePicker);
            // return
            // return;

            if(this.title =='' ||  this.body =='' ||  this.summary ==''||  this.link =='') return;
            if(this.dates.length == 0) return;
             

            // var tempUrl = '';
            // if(this.imgLink != '')
            // {
            //    tempUrl = this.imgLink;
            // }
            // else if (this.youtubeID != '')
            // {
            //     tempUrl = 'https://img.youtube.com/vi/'+ this.youtubeID +'/0.jpg';
            // }
           
            await addDoc(collection(db, "calendars"), 
            {
                title:  this.title,
                body: this.body.replaceAll('\n','<br>'),
                summary:this.summary.replaceAll('\n','<br>'),
                link: this.link,
                imgLink:this.imgLink,
                startDate:this.dates[0],
                endDate:this.dates.length == 2 ? this.dates[1]:null,
                color:this.colorPicker.hexa,
                startTime: this.startTimePicker,
                willComming:this.willComming
            });
        
            this.title = '';
            this.body = '';
            this.summary = '';
            this.link = '';
            this.imgLink = '';
            this.dates = [];
            this.colorPicker= null;
            this.startTimePicker = null;
            this.willComming = '';
 
        }

  },
  mounted() {
      
  },
}
</script>

 