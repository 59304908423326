 
<template>
   <v-main style="background-color:#F2F3F8">
   
   <v-container>

     
        <div>
          <v-alert
                color="red darken-1"
                dark
                icon="mdi-alert-octagram"
                
                elevation="1"
                
                dismissible
                dense
                prominent>

               면책사항: 로드맵의 일정은 해당 프로젝트의 일정에 따라서 변경이 있을수 있습니다. 본 로드맵은 현재의 상황과 미래예측에 대한 진술일 뿐 어떠한 투자의 목적이나 수단으로 활용이 될  수 없으며 모든 투자의 책임은 투자자 본인에게 있음을 알려드립니다.
          </v-alert>
 
        </div>

    <v-row>

         <!--메인:왼쪽 열  -->
          <v-col
            cols="12"
         
            sm="3">

            <v-card>

              <v-list-item  >
                  <v-icon color="primary">mdi-rocket-launch-outline</v-icon>
                   <h3 class="ml-2">Upcomming Events</h3>
                </v-list-item>

            <template>
            <v-expansion-panels focusable :value="0" >
                <v-expansion-panel 
                v-for="(item,i) in upCommingEvents"
                :key="i"
                
                >
                <v-card elevation="3">

                  <v-expansion-panel-header>

                    <v-list-item style="margin-left:-23px">
                     <v-icon color="grey">mdi-rocket-launch-outline</v-icon>
                    
                     <h4 class=" ml-2">{{item.data().title + ' (' + getDday(item.data().startDate) + ')'  }} </h4>
                    </v-list-item>

                  </v-expansion-panel-header>
                <v-expansion-panel-content>
                
                     <v-img class="ma-2 rounded-xl"   
                        :src="item.data().imgLink">
                    </v-img>
                    <v-subheader style="margin-left:-20px">
                        <v-icon color="secondary" class="mr-1" small >mdi-calendar-clock</v-icon>
                      {{getEventDuration(item.data().startDate,item.data().endDate,item.data().startTime)}}</v-subheader>

                     <div class="mt-2"  v-html="item.data().summary"></div>

                    <v-container fill-width>
                       <div  class="d-flex justify-end align-center mb-1">
                          <v-chip 
                            class="mr-1"
                            color="blue lighten-5"
                            @click="showDetail(item)"
                           >Read more</v-chip>
                           <v-chip 
                            color="grey lighten-3"
                            @click="openLink(item.data().link == null ? '' :item.data().link)"
                           >Visit</v-chip>
                       </div>
                    </v-container>

                </v-expansion-panel-content>

                </v-card>
               
                </v-expansion-panel>
            </v-expansion-panels>
            </template>

            </v-card>
           
          </v-col>
  
          <v-col
            cols="12"
            sm="6">

            <v-row>

              <v-col>

        <v-sheet height="64">
          <v-toolbar
            flat
          >
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          
          </v-toolbar>
        </v-sheet>

        <v-sheet :height="calendarHeight">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @change="updateRange"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
            >
              <v-toolbar
                :color="selectedEvent.color"
                dark
              >
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.details"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>


              </v-col>

            </v-row>

            <v-row>

              <v-col  ref="detailInfo">

                <v-sheet
                  
                    rounded="lg"
                    color="#f5f5f5"
                    v-show="this.selectedEventData != null" >

              
                <v-card
                  class="mx-auto rounded-xl"
                  color="white"
                  style="margin:17px 10px 10px 10px;"
                  >

                <v-container fill-height>
                     <v-img   
                        class="rounded-xl" 
                        min-height="200" 
                        max-height="300" 
                       v-if="selectedEventData ==null ? false : selectedEventData.imgLink != ''"
                        :src="selectedEventData ==null ? '' :selectedEventData.imgLink == '' ? '' : selectedEventData.imgLink"></v-img>
                  </v-container>


                <v-card-title>
                      <span class="text-h5 font-weight-bold">
                        {{selectedEventData ==null ? '' : selectedEventData.title}}
                      </span>
                  </v-card-title>

                  <v-card-subtitle>
                    
                     <v-icon color="secondary" small >mdi-calendar-clock</v-icon>

                    Event Day : 

                   {{getSelectedEventDuration}}

                  </v-card-subtitle>

                  <v-card-text class="text-h6 font-weight-light">
                    <div v-html="selectedEventData ==null ? '' : selectedEventData.body"></div>
                  
                  </v-card-text>

                  <v-card-actions>

                  <v-chip color="blue lighten-5" text
                   @click="openLink(selectedEventData ==null ? '' :selectedEventData.link)"
                  >Read More</v-chip>

                   <v-chip  
                        @click="closeDetail"
                          color="grey"
                          text-color="white"
                          class="ml-2" >Close</v-chip>

                   <v-chip  v-show="isAdmin" 
                        @click="UpdateCalendar(selectedEventData ==null ? '' : selectedDocID)"
                          color="secondary"
                          text-color="white"
                          class="ml-2" >Update</v-chip>
              
                  <v-chip  v-show="isAdmin" 
                        @click="deleteCalendar(selectedEventData ==null ? '' : selectedDocID)"
                          color="red"
                          text-color="white"
                          class="ml-2" >Delete</v-chip>

                  </v-card-actions>
                  </v-card>
                        
                </v-sheet>

              </v-col>

            </v-row>

            <v-row>
              <v-col>
                 <AddCalendar v-if="isAdmin" ref="refAddCalendar"/>
              </v-col>
            </v-row>
           
          </v-col>

           <v-col
            cols="12"
            sm="3">
             <v-row>
                 <v-col>

                <WillComming v-on:showDetailP="showDetail" />

                 </v-col>
             </v-row>
           
           
          </v-col>

    </v-row>


   </v-container>


  </v-main>
</template>



<script>

import AddCalendar from '@/components/AddCalendar'
import WillComming from '@/components/WillComming'

import { collection, getDocs,getDoc,
         query, orderBy, limit,
         doc,deleteDoc,
         where
        } from "firebase/firestore"; 

import { db } from '@/plugins/firebase';

  export default {

    components:{

      AddCalendar,
      WillComming

    },

    data: () => ({
      isAdmin :false,
      unsubscribe:null,
      snapshots: [],
      focus: '',
      type: 'month',
      selectedEvent: {},
      selectedEventData:null,
      selectedDocID:'',
      selectedElement: null,
      selectedOpen: false,
      events: [],
      upCommingEvents:[],
      calendarHeight:550
    }),
    computed : {

       getSelectedEventDuration()
       {

          if(this.selectedEventData == null) return '';
          if(this.selectedEventData.willComming != '') return this.selectedEventData.willComming;

          if(this.selectedEventData.endDate == null)
          {

            if(this.selectedEventData.startTime == null)
            {
                return this.getLocalTime(this.selectedEventData.startDate,'')
                
            }
            else{

                return this.getLocalTime(this.selectedEventData.startDate,this.selectedEventData.startTime)
            }
             
          }
          else{

             if(this.selectedEventData.startTime == null)
            {
                return this.getLocalTime(this.selectedEventData.startDate,'') + ' ~ ' + this.getLocalTime(this.selectedEventData.endDate,'');
            }
            else{

              return this.getLocalTime(this.selectedEventData.startDate,this.selectedEventData.startTime) + ' ~  ' + this.getLocalTime(this.selectedEventData.endDate,'');

            }

          }
       },
      
    },
    watch: {

  '$store.state.isFirebaseAdmin': function() {

    if(this.$store.state.isFirebaseAdmin)
    {
      //true
      this.isAdmin = true;
    }
    else{
      //false
      this.isAdmin = false;

    }

   }
  },
  
    mounted () {

      //this.timeZoneDiff = (new Date().getTimezoneOffset()/60);

       if(window.innerWidth > 600)
       {
           this.calendarHeight = 750;
       }
       
      this.$refs.calendar.checkChange()
      this.loadUpCommingEvent()
     

    if(this.$store.state.isFirebaseAdmin)
    {
      //true
      this.isAdmin = true;
    }
    else{
      //false
      this.isAdmin = false;

    }
     this.$vuetify.goTo(0)

    },
    methods: {

      showDetail(item){
        
        this.selectedEventData = item.data();
        this.selectedDocID = item.id;

        this.$vuetify.goTo(this.$refs.detailInfo)

      },
      closeDetail(){

          this.selectedEventData = null
          this.selectedDocID = ''
            
        },
       getLocalTime(date,time)
       {
           var nowDateTimeStamp;

           var tempParcedDate = date.split('-');

           if(time=='')
           {
               nowDateTimeStamp = new Date(Date.UTC(tempParcedDate[0], tempParcedDate[1]-1,tempParcedDate[2]));    
           }
           else
           {
              var tempParcedTime =  time.split(':');
              nowDateTimeStamp = new Date(Date.UTC(tempParcedDate[0], tempParcedDate[1]-1,tempParcedDate[2],tempParcedTime[0],tempParcedTime[1],0));    
           }
            
            var now = nowDateTimeStamp;
            var year = now.getFullYear()
            var month = (now.getMonth()+1).toString().length == 1 ? '0' +(now.getMonth()+1).toString():(now.getMonth()+1);
            var day = now.getDate().toString().length == 1 ? '0' + now.getDate().toString():now.getDate();

            
           if(time=='')
           {
               return `${year}-${month}-${day}`;
           }
           else{
                var hours =  now.getHours().toString().length == 1 ? '0' + now.getHours().toString():now.getHours();
                var min =   now.getMinutes().toString().length == 1 ? '0' + now.getMinutes().toString():now.getMinutes();
                return  `${year}-${month}-${day} ${hours}:${min}`;
           }
          
       },
       openLink(linkURL)
       {
         console.log('called');
         if(linkURL=="") return;
         window.open(linkURL, '_blank');
       },
       getEventDuration(start,end,time)
       {
          if(end == null)
          {

            if(time == null)
            {
              return "Event Day : " + this.getLocalTime(start,'')
            }
            else{

               return "Event Day : " + this.getLocalTime(start,time)

            }
            
          }
          else{

            if(time == null)
            {
              return  "Event Day : " + this.getLocalTime(start,'') + ' ~ ' + this.getLocalTime(end,'')
            }
            else{
               return  "Event Day : " + this.getLocalTime(start,time) + ' ~ ' +this.getLocalTime(end,'')
            }
           
          }
       },

       getDday(upcommingDate)
       {
         var now = new Date();   

         var year = now.getFullYear()
         var month = (now.getMonth()+1).toString().length == 1 ? '0' +(now.getMonth()+1).toString():(now.getMonth()+1);
         var day = now.getDate().toString().length == 1 ? '0' + now.getDate().toString():now.getDate();

        var nowStartYearMonth = `${year}-${month}-${day}`;

        if(nowStartYearMonth == upcommingDate) return 'Today!';

         var uDate = new Date(upcommingDate);
         const diffTime = Math.abs(uDate - now);
         const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

         return diffDays > 1 ? "-" + diffDays + ' Days' :"-" + diffDays + ' Day' ;
       },


      async UpdateCalendar(docID)
      {
          const docRef = doc(db,'calendars',docID)
          const docSnap = await getDoc(docRef)
          
          this.$refs.refAddCalendar.setUpdate(docSnap.data(),docID)
 
      },
      async deleteCalendar(docID)
      {
        var ref = doc(db,'calendars',docID);
        await deleteDoc(ref);

        await this.loadUpCommingEvent();

        
        var now = new Date();
        var year = now.getFullYear()
        var month = (now.getMonth()+1).toString().length == 1 ? '0' +(now.getMonth()+1).toString():(now.getMonth()+1);

        var nowStartYearMonth = `${year}-${month}-01`;
        await this.loadCalendar(nowStartYearMonth);

        this.selectedEventData =null;
        this.selectedDocID = null;

      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },

      async loadUpCommingEvent()
      {
       
        var now = new Date();   
        
        var year = now.getUTCFullYear()
        var month = (now.getUTCMonth()+1).toString().length == 1 ? '0' +(now.getUTCMonth()+1).toString():(now.getUTCMonth()+1);
        var day = now.getUTCDate().toString().length == 1 ? '0' + now.getUTCDate().toString():now.getUTCDate();

        var nowStartYearMonth = `${year}-${month}-${day}`;
         
        const q = query(collection(db, "calendars"),
                          where("startDate", '>=',nowStartYearMonth),
                          orderBy("startDate", "asc"),
                          limit(20));
        const querySnapshot = await getDocs(q);
        
        const onlyUpcommingEvents = []
        querySnapshot.docs.map((value,index) =>{

            if(value.data().willComming=='' && onlyUpcommingEvents.length < 5)
            {
                onlyUpcommingEvents.push(value)
            }

         })

         this.upCommingEvents = onlyUpcommingEvents;

        
      },

      async updateRange ({ start, end }) {
        
        this.loadCalendar(start.date);
        
      },

      async loadCalendar(dateStart)
      {
        var tempStartDate = new Date(dateStart);
        tempStartDate.setMonth(tempStartDate.getMonth() - 1)

        var year = tempStartDate.getFullYear()
        var month = (tempStartDate.getMonth()+1).toString().length == 1 ? '0' +(tempStartDate.getMonth()+1).toString():(tempStartDate.getMonth()+1);

        var nowStartYearMonth = `${year}-${month}-01`;

        //get Next Date
        tempStartDate = new Date(dateStart);
        tempStartDate.setMonth(tempStartDate.getMonth() + 1)

        year = tempStartDate.getFullYear()
        month = (tempStartDate.getMonth()+1).toString().length == 1 ? '0' +(tempStartDate.getMonth()+1).toString():(tempStartDate.getMonth()+1);

        var nowEndYearMonth = `${year}-${month}-31`;

        const events = []
       
        const q = query(

              collection(db, "calendars"),
              where("startDate", '>=',nowStartYearMonth),
              where("startDate", '<=',nowEndYearMonth));

        const querySnapshot = await getDocs(q);
       
        querySnapshot.docs.map((value, index) => {
          
          var tempParcedAssets = value.data().startDate;
          var newStartTimestamp;
          tempParcedAssets = tempParcedAssets.split('-');

          if(value.data().startTime == null)
          {
            //newStartTimestamp = new Date(tempParcedAssets[0], tempParcedAssets[1]-1,tempParcedAssets[2]);
            newStartTimestamp = new Date(tempParcedAssets[0], tempParcedAssets[1]-1,tempParcedAssets[2]);
          }
          else{

            var tempParcedTime = value.data().startTime;
            tempParcedTime = tempParcedTime.split(':');

            //newStartTimestamp = new Date(tempParcedAssets[0], tempParcedAssets[1]-1,tempParcedAssets[2],tempParcedTime[0],tempParcedTime[1],0);
            newStartTimestamp = new Date(Date.UTC(tempParcedAssets[0], tempParcedAssets[1]-1,tempParcedAssets[2],tempParcedTime[0],tempParcedTime[1],0));

          }

          var newEndTimestamp ;
          if(value.data().endDate == null){

            newEndTimestamp = newStartTimestamp;

          }
          else{

            tempParcedAssets = value.data().endDate;
            tempParcedAssets = tempParcedAssets.split('-');
            //newEndTimestamp =  new Date(tempParcedAssets[0], tempParcedAssets[1]-1,tempParcedAssets[2]);
            newEndTimestamp =  new Date(Date.UTC(tempParcedAssets[0], tempParcedAssets[1]-1,tempParcedAssets[2]));

          }
          
        //   console.log('UTC');
        //   console.log(newStartTimestamp);
        //   console.log(newEndTimestamp);

        //   console.log('GET HOURS');
        //   console.log(newStartTimestamp.getHours() );
        //   console.log(newEndTimestamp.getHours());

        //   //local 시간으로 변경
        //   newStartTimestamp.setHours(newStartTimestamp.getHours() + this.timeZoneDiff);
        //   newEndTimestamp.setHours(newEndTimestamp.getHours() + this.timeZoneDiff);

        //   console.log('LOCAL');
        //   console.log(newStartTimestamp);
        //   console.log(newEndTimestamp);

          if(value.data().willComming =='')
          {

           events.push({

            name: value.data().title,
            start: newStartTimestamp,
            end:newEndTimestamp,
            color: value.data().color,
            timed: value.data().startTime != null,
            data:value.data(),
            docID:value.id,
          })}

        });

        this.events = events
      },
      showEvent({ nativeEvent, event })
      {
          this.selectedEventData = event.data;
          this.selectedDocID = event.docID;

          this.$refs.refAddCalendar.cancel()
      },
       
    },
  }
</script>