 
<template>
   
       <v-card>

          <v-list-item  >
              <v-icon color="deep-orange">mdi-rocket</v-icon>
               <h3 class="ml-2">Comming Soon</h3>
            </v-list-item>

        <template>
        <v-expansion-panels focusable >
            <v-expansion-panel 
            v-for="(item,i) in willCommingEvents"
            :key="i"
            
            >
            <v-card elevation="3">

              <v-expansion-panel-header>

                <v-list-item style="margin-left:-23px">
                 <v-icon color="grey">mdi-rocket</v-icon>
                
                 <h4 class=" ml-2">{{item.data().title}} </h4>
                </v-list-item>

              </v-expansion-panel-header>
                <v-expansion-panel-content>
                    
            
                    <v-img class="ma-2 rounded-xl"
                        :src="item.data().imgLink">
                    </v-img>
                    <v-subheader style="margin-left:-20px">
                        <v-icon color="secondary" class="mr-1" small >mdi-calendar-clock</v-icon>
                        {{item.data().willComming}}
                   </v-subheader>

                    <div class="mt-2"  v-html="item.data().summary"></div>

                    <v-container fill-width>
                    <div  class="d-flex justify-end align-center mb-1">
                        <!-- <v-chip color="secondary" class="mr-1"  v-show="isAdmin" >update</v-chip>
                        <v-chip color="error" class="mr-1"  v-show="isAdmin">delete</v-chip> -->
                         <v-chip 
                            class="mr-1"
                            color="blue lighten-5"
                           
                            v-on:click="$emit('showDetailP',item)"
                           >Read more</v-chip>
                           <v-chip 
                            color="grey lighten-3"
                            @click="openLink(item.data().link == null ? '' :item.data().link)"
                           >Visit</v-chip>
                    </div>
                    </v-container>

                    
                    

                </v-expansion-panel-content>

          

            </v-card>
           
            </v-expansion-panel>
            </v-expansion-panels>
            </template>

            </v-card>

</template>

<script>




import { collection, getDocs,
         query, orderBy, limit,
         where
        } from "firebase/firestore"; 

import { db } from '@/plugins/firebase';

 

export default ({

   data() {
    return {
    isAdmin :false,
    willCommingEvents:[],
     
    }},

  
  methods: {

     
     openLink(linkURL)
     {
        if(linkURL=="") return;
        window.open(linkURL, '_blank');
     },

      async loadEvent()
      {
       
        var now = new Date();   
        
        var year = now.getUTCFullYear()
        var month = (now.getUTCMonth()+1).toString().length == 1 ? '0' +(now.getUTCMonth()+1).toString():(now.getUTCMonth()+1);
        var day = now.getUTCDate().toString().length == 1 ? '0' + now.getUTCDate().toString():now.getUTCDate();

        var nowStartYearMonth = `${year}-${month}-${day}`;
       
        const q = query(collection(db, "calendars"),where("startDate", '>=',nowStartYearMonth), orderBy("startDate", "asc"), limit(50));
        const querySnapshot = await getDocs(q);

        const onlyWillcommingEvents = []
        querySnapshot.docs.map((value,index) =>{

            if(value.data().willComming !='')
            {
                onlyWillcommingEvents.push(value)
            }

         })

         this.willCommingEvents = onlyWillcommingEvents;

        
      },
       getDday(upcommingDate)
       {
         var now = new Date();   

         var year = now.getFullYear()
         var month = (now.getMonth()+1).toString().length == 1 ? '0' +(now.getMonth()+1).toString():(now.getMonth()+1);
         var day = now.getDate().toString().length == 1 ? '0' + now.getDate().toString():now.getDate();

        var nowStartYearMonth = `${year}-${month}-${day}`;

        if(nowStartYearMonth == upcommingDate) return 'Today!';

         var uDate = new Date(upcommingDate);
         const diffTime = Math.abs(uDate - now);
         const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

         return diffDays > 1 ? "-" + diffDays + ' Days' :"-" + diffDays + ' Day' ;
       },
       getEventDuration(start,end,time)
       {
          if(end == null)
          {

            if(time == null)
            {
              return "Event Day : " + this.getLocalTime(start,'')
            }
            else{

               return "Event Day : " + this.getLocalTime(start,time)

            }
            
          }
          else{

            if(time == null)
            {
              return  "Event Day : " + this.getLocalTime(start,'') + ' ~ ' + this.getLocalTime(end,'')
            }
            else{
               return  "Event Day : " + this.getLocalTime(start,time) + ' ~ ' +this.getLocalTime(end,'')
            }
           
          }
       },
        getLocalTime(date,time)
       {
           var nowDateTimeStamp;

           var tempParcedDate = date.split('-');

           if(time=='')
           {
               nowDateTimeStamp = new Date(Date.UTC(tempParcedDate[0], tempParcedDate[1]-1,tempParcedDate[2]));    
           }
           else
           {
              var tempParcedTime =  time.split(':');
              nowDateTimeStamp = new Date(Date.UTC(tempParcedDate[0], tempParcedDate[1]-1,tempParcedDate[2],tempParcedTime[0],tempParcedTime[1],0));    
           }
            
            var now = nowDateTimeStamp;
            var year = now.getFullYear()
            var month = (now.getMonth()+1).toString().length == 1 ? '0' +(now.getMonth()+1).toString():(now.getMonth()+1);
            var day = now.getDate().toString().length == 1 ? '0' + now.getDate().toString():now.getDate();

            
           if(time=='')
           {
               return `${year}-${month}-${day}`;
           }
           else{
                var hours =  now.getHours().toString().length == 1 ? '0' + now.getHours().toString():now.getHours();
                var min =   now.getMinutes().toString().length == 1 ? '0' + now.getMinutes().toString():now.getMinutes();
                return  `${year}-${month}-${day} ${hours}:${min}`;
           }
          
       },
    
  },
  watch: {

  '$store.state.isFirebaseAdmin': function() {

    if(this.$store.state.isFirebaseAdmin)
    {
      //true
      this.isAdmin = true;
    }
    else{
      //false
      this.isAdmin = false;

    }
  }
  },
  mounted() {

   this.loadEvent();

    if(this.$store.state.isFirebaseAdmin)
    {
      //true
      this.isAdmin = true;
    }
    else{
      //false
      this.isAdmin = false;

    }
     
  },
})
</script>
